<template>
  <v-dialog v-model="dialog" max-width="500px" rigin="center center">
    <v-card>
      <v-card-text>
        <div class="row col-xs">
          <div class="col-md">
            <v-text-field
              v-model="filter.customer_name"
              label="Cliente"
              item-text="name"
              item-value="id"
              dense
              filled
              outlined
              hide-details="auto"
            ></v-text-field>
          </div>
        </div>
        <div class="row col-xs">
          <div class="col-md">
            <money-input
              ref="minValueRef"
              label="Valor Mínimo"
              v-model="filter.valueMin"
            ></money-input>
          </div>
          <div class="col-md">
            <money-input
              ref="maxValueRef"
              label="Valor Máximo"
              v-model="filter.valueMax"
              :rules="[rules.greaterThanMin]"
            ></money-input>
          </div>
        </div>
        <div class="row col-xs">
          <div class="col-md">
            <date-picker
              ref="emitedPickerRef"
              v-model="date"
              :range="true"
            ></date-picker>
          </div>
          <div class="col-md">
            <v-select
              v-model="filter.status"
              :items="selectStatus"
              item-text="text"
              item-value="value"
              placeholder="Status"
              outlined
              dense
            ></v-select>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="submitFilter()"> Aplicar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import datePicker from "@/components/app/ui/datePicker.vue";
import moneyInput from "@/components/app/ui/moneyInput.vue";
import formMixin from '@/util/mixins/formMixin';

export default {
  components: {
    moneyInput,
    datePicker,
  },

  mixins: [formMixin],

  data() {
    return {
      dialog: false,
      loading: false,
      processedData: null,
      menuData: false,

      date: [],

      filter: {
        company_name: null,
        customer_name: null,
        valueMin: null,
        valueMax: null,
        ordered_at_min: null,
        ordered_at_max: null,
        status: null,
        is_filter: true,
      },

      rules: {
        greaterThanMin: (v) =>
          this.filter.valueMin <= this.filter.valueMax ||
          "O valor máximo deve ser maior que o valor minimo.",
      },

      selectStatus: [
        { text: "Rascunho", value: "draft" },
        { text: "Pendente", value: "pendding" },
        { text: "Faturado", value: "billed" },
        { text: "Cancelado", value: "cancelled" },
      ],
    };
  },

  methods: {
    open() {
      this.filter = {
        company_name: null,
        customer_name: null,
        valueMin: null,
        valueMax: null,
        ordered_at_min: null,
        ordered_at_max: null,
        status: null,
        is_filter: true,
      };
      this.dialog = true;
    },

    handlingDate(date) {
      if (date == null) {
        this.filter.ordered_at_min = null;
        this.filter.ordered_at_max = null;
      } else {
        this.filter.ordered_at_min = date[0];
        this.filter.ordered_at_max = date[1];
      }
    },

    submitFilter() {
      if (this.filter.valueMin == 0) {
        this.filter.valueMin = null;
      }

      if (this.filter.valueMax == 0) {
        this.filter.valueMax = null;
      }

      this.handlingDate(this.date);

      this.$emit("filter", this.filter);
      this.dialog = false;
    },
  },
};
</script>

<style>
.v-text-field__prefix {
  margin-top: 0px !important;
}
</style>