<template>
  <div class="text-center">
    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Privacy Policy
        </v-card-title>
        <v-card-text>
          <vue-json-pretty
            :path="'res'"
            :data="data"
            @click="handleClick"
          >
          </vue-json-pretty>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";

export default {
  components: {
    VueJsonPretty,
  },

  data() {
    return {
      dialog: false,
      data: null
    };
  },

  methods: {
    open(item) {
        this.data = item
      this.dialog = true;
    },
  },
};
</script>
