<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    origin="center center"
    persistent
    :fullscreen="isMobile"
  >
    <v-card>
      <v-card-title> Emitir Nota Fiscal de Saída Manualmente </v-card-title>
      <div v-if="loading" class="d-flex justify-center">
        <v-progress-circular indeterminate color="secondary" class="ma-2" />
      </div>
      <v-form v-show="!loading" ref="form" v-model="formValid">
        <v-card-text>
          <div class="row col-xs">
            <div class="col-md">
              <v-select
                label="cliente"
                :filterable="false"
                :options="customersFiltered"
                @search="searchCustomer($event)"
                v-model="formInvoice.customer"
              >
                <template slot="no-options">
                  Digite o nome do cliente..
                </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.name }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }}
                  </div>
                </template>
              </v-select>
            </div>
          </div>
          <div class="row col-xs">
            <div class="col-md">
              <v-autocomplete
                v-model="formInvoice.service"
                :items="codeServices"
                label="Serviços"
                item-text="description"
                item-value="id"
                :rules="[rules.required]"
                dense
                filled
                outlined
                hide-details="auto"
              ></v-autocomplete>
            </div>
          </div>
          <div class="row col-xs">
            <div class="col-md">
            <v-text-field
              v-model="formInvoice.description"
              :rules="[rules.required]"
              label="Descrição"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </div>
          </div>
          <div class="row col-xs">
            <div class="col-md">
              <money-input
                ref="valueInputRef"
                label="Valor"
                v-model="formInvoice.value"
                :rules="[rules.required]"
              ></money-input>
            </div>
            <div class="col-md">
              <date-picker
                ref="emitedPickerRef"
                v-model="formInvoice.emited_at"
                label="Data Emissão"
              ></date-picker>
            </div>
          </div>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="text-capitalize" color="secondary" @click="close">
          Cancelar
        </v-btn>
        <v-btn class="text-capitalize" color="success" @click="submitForm()">
          <v-icon>{{ icons.mdiContentSave }}</v-icon>
          Salvar Informações
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiContentSave } from "@mdi/js";
import moneyInput from "@/components/app/ui/moneyInput.vue";
import datePicker from "@/components/app/ui/datePicker.vue";
import formMixin from "@/util/mixins/formMixin";
import "vue-select/dist/vue-select.css";

export default {
  name: "outgoingInvoiceForm",

  components: {
    moneyInput,
    datePicker,
    vSelect: () => import("vue-select"),
  },

  mixins: [formMixin],

  data() {
    return {
      dialog: false,
      formValid: true,
      loading: false,
      menuEmited: false,

      formInvoice: {
        customer: null,
        service: null,
        description: null,
        value: null,
        emited_at: null,
      },

      customers: [],
      customersFiltered: [],

      filterSelect: {
        is_filter: false,
        q: null,
        fieldTable: "name",
      },

      timeOutId: null,

      icons: {
        mdiContentSave,
      },
    };
  },

  beforeMount() {
    this.getCodeService();
    this.getOutgoingInvoice();
  },

  computed: {
    codeServices() {
      return this.$store.state.codeService.codeServices;
    },
  },

  methods: {
    async getCodeService() {
      await this.$store.dispatch("codeService/getCodeServices");
    },
    
    open() {
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.customersFiltered = [];
      this.formInvoice.customer = null
    },

    getOutgoingInvoice() {
      this.loading = true;

      this.$http
        .$get("/customer")
        .then((res) => {
          this.customers = res.data.data;
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },

    submitForm() {
      if (this.$refs.form.validate()) {
        this.$emit("onSubmit", this.formInvoice);
        this.dialog = false;
      }
    },

    searchCustomer(event) {
        clearTimeout(this.searchCustomerTimeOutId);
        this.timeOutId = setTimeout(() => {
          this.filterSelect.is_filter = true;
          this.filterSelect.q = event;

          this.$http
            .$get("/customer", this.filterSelect)
            .then((res) => {
              this.customersFiltered = res.data.data;

              this.loading = false;
            })
            .catch((err) => console.log(err));
        }, 1000);
    },
  },

  watch: {
    dialog() {
      if (!this.dialog) {
        this.$refs.valueInputRef.reset();
        this.$refs.emitedPickerRef.reset();
        this.$refs.form.reset();
        this.customersFiltered = [];
        this.formInvoice.customer = null
      }
    },
  },
};
</script>

<style scoped>
  >>> {
    --vs-search-input-bg: rgba(231, 227, 252, 0.03);
    --vs-controls-color: #664cc3;
    --vs-border-color: #664cc3;
  
    --vs-dropdown-bg: rgba(231, 227, 252, 0);
    --vs-dropdown-color: #cc99cd;
    --vs-dropdown-option-color: #cc99cd;
  
    --vs-selected-bg: #282c34;
    --vs-selected-color: #eeeeee;
  
    --vs-search-input-color: #eeeeee;
  
    --vs-dropdown-option--active-bg: #664cc3;
    --vs-dropdown-option--active-color: #eeeeee;
  }

  input {
    color:brown
  }
  </style>